<template>
  <div class="loginpage">
    <p class="loginP">重置密码</p>
    <van-form @submit="onSubmit">
      <div class="inline">
        <van-field
          v-model="phone"
          type="number"
          name="phone"
          placeholder="请输入法人手机号"
          :rules="[{ required: true, validator }]"
        />
      </div>
      <!-- 设置密码 -->
      <div class="resetPwd">
        <div class="inline">
          <van-field
            v-model="newPass"
            type="password"
            name="newPass"
            placeholder="请输入新密码"
            :rules="[{ required: true }]"
          />
        </div>
        <div class="inline">
          <van-field
            v-model="code"
            name="code"
            placeholder="请输入验证码"
            :rules="[{ required: true }]"
          />
          <div class="huoqYZM" @click="getYanZhM" v-if="showGetYZM">
            获取验证码
          </div>
          <div class="huoqYZM" @click="getYanZhM" v-if="!showGetYZM">
            {{ oneMinate }}s后重新获取
          </div>
        </div>
      </div>
      <div style="margin: 50px">
        <van-button
          round
          block
          type="info"
          native-type="submit"
          :loading="formIng"
          >重置</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
import url from "@/service/url-config";
export default {
  data() {
    return {
      phone: "",
      code: "",
      password: "",
      newPass: "",
      showGetYZM: true, //显示获取验证码
      oneMinate: 60,
      timer: null,
      codeLogin: true, //账号验证码登录
      isCommit: true, //正常登录
      showXieYe: false, //显示弹窗
      formIng: false,
    };
  },
  methods: {
    //手机号校验
    validator(val) {
      return /^(1)\d{10}$/.test(val);
    },
    //获取验证码计时器
    getYanZhM() {
      if (this.validator(this.phone)) {
        this.showGetYZM = false;
        this.oneMinate=60
        this.sendSMS();
        if (this.timer) clearInterval(this.timer);
        this.timer = setInterval(() => {
          this.oneMinate -= 1;
          if (this.oneMinate < 1) {
            clearInterval(this.timer);
            this.showGetYZM = true;
            this.timer = null;
          }
        }, 1000);
      } else {
        this.$dialog.alert({
          message: "请先输入手机号",
        });
      }
    },
    //获取验证码
    sendSMS() {
      this.$http
        .post(url.sendSMS, {
          phone: this.phone,
        })
        .then((res) => {
          if (res.code !== 200) {
            this.$dialog.alert({
              message: res.msg,
            });
          }
        });
    },
    // 切换到账号密码登录
    codeOrPwd() {
      this.codeLogin = !this.codeLogin;
    },
    //切换到重置密码登录
    missPwd() {
      this.isCommit = false;
    },
    onSubmit(values) {
      this.formIng = true;
      const param = {
        ...values,
        appId: "H5",
        appVersion: "1.0.0.1",
        clientId: "yqhd",
      };
      this.$http.post(url.phoneResetPwd, param).then((res) => {
        this.formIng = false;
        if (res.code == 200) {
          if (res.data == 1) {
            this.$toast("密码设置成功,请重新登录");
            localStorage.clear();
            setTimeout(() => {
              this.$router.replace({ name: "login" });
            }, 2000);
          }
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.loginpage {
  width: 100%;
  box-sizing: border-box;
  padding: 30px 20px;

  .loginP {
    font-weight: 500;
    color: #333333;
    line-height: 110px;
    font-size: 28px;
    padding-left: 20px;
  }

  .inline {
    display: flex;
    border-bottom: 1px solid rgba(235, 237, 240);
    margin: 0 20px;

    .van-cell::after {
      border-bottom: 0;
    }

    .huoqYZM {
      width: 150px;
      font-size: 14px;
      line-height: 50px;
      color: rgba(0, 132, 255, 1);
    }
  }

  .toggle {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    font-size: 12px;
    line-height: 25px;
    color: rgba(0, 132, 255, 1);
  }

  .van-action-sheet {
    height: 50vh;

    .content {
      padding: 20px;
      font-size: 12px;
      font-weight: 400;

      ._p {
        text-indent: 2em;
      }
    }
  }
}
</style>